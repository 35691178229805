import React from "react";
import {
	Box,
	Flex,
	Text,
	IconButton,
	Stack,
	Collapse,
	Icon,
	Heading,
	Link,
	useColorModeValue,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import RouterLink from "next/link";

export default function Navbar() {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Box
			position="sticky"
			top={0}
			zIndex="99"
			width="full"
			borderTop="3px solid"
			borderTopColor="teal.400">
			<Flex
				bg="white"
				color="gray.600"
				minH="60px"
				py={{ base: 3, md: 3 }}
				px={{ base: 4 }}
				align="center"
				transition="box-shadow 0.2s"
				shadow="sm">
				<Flex
					flex={{ base: 1, md: "auto" }}
					ml={{ base: -2 }}
					display={{ base: "flex", md: "none" }}>
					<IconButton
						onClick={onToggle}
						icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
						variant="ghost"
						aria-label="Toggle Navigation"
					/>
				</Flex>

				<Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
					<Link as={RouterLink} href="/">
						<Heading size="md" textAlign={useBreakpointValue({ base: "center", md: "left" })}>
							Buck{" "}
							<Box as="span" color="teal.500">
								Cheap
							</Box>
						</Heading>
					</Link>

					<Flex display={{ base: "none", md: "flex" }} ml={10}>
						<DesktopNav />
					</Flex>
				</Flex>

				<Flex flex={1} />
			</Flex>

			<Collapse in={isOpen} animateOpacity>
				<MobileNav />
			</Collapse>
		</Box>
	);
}

const DesktopNav = () => {
	const linkColor = useColorModeValue("gray.600", "gray.200");

	return (
		<Stack direction="row" spacing={4}>
			{NAV_ITEMS.map((navItem) => (
				<Box key={navItem.label}>
					<Link
						as={RouterLink}
						p={2}
						href={navItem.href ?? "/"}
						fontSize="sm"
						fontWeight={500}
						color={linkColor}>
						{navItem.label}
					</Link>
				</Box>
			))}
		</Stack>
	);
};

const MobileNav = () => {
	return (
		<Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{ md: "none" }}>
			{NAV_ITEMS.map((navItem) => (
				<MobileNavItem key={navItem.label} {...navItem} />
			))}
		</Stack>
	);
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Stack spacing={4} onClick={children && onToggle}>
			<Flex py={2} as={Link} href={href ?? "/"} justify="space-between" align="center">
				<Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")}>
					{label}
				</Text>
				{children && (
					<Icon
						as={ChevronDownIcon}
						transition="all .25s ease-in-out"
						transform={isOpen ? "rotate(180deg)" : ""}
						w={6}
						h={6}
					/>
				)}
			</Flex>

			<Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
				<Stack
					mt={2}
					pl={4}
					borderLeft={1}
					borderStyle="solid"
					borderColor={useColorModeValue("gray.200", "gray.700")}
					align="start">
					{children &&
						children.map((child) => (
							<Link key={child.label} py={2} href={child.href}>
								{child.label}
							</Link>
						))}
				</Stack>
			</Collapse>
		</Stack>
	);
};

interface NavItem {
	label: string;
	children?: Array<NavItem>;
	href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
	{
		label: "Browser Extension",
		href: "/Extension",
	},
	{
		label: "Support Us ♥️",
		href: "https://www.buymeacoffee.com/ogbuckcheap",
	},
	{
		label: "About Us",
		href: "/AboutUs",
	},
];
