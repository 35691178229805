import React, { useState } from "react";
// @ts-ignore
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import qs from "qs";
import { useRouter } from "next/router";
import { SearchState } from "react-instantsearch-core";
import config from "../lib/config";

const { searchClient } = new TypesenseInstantSearchAdapter({
	server: {
		apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_ONLY_API_KEY ?? "", // Be sure to use an API key that only allows searches, in production
		nodes: [
			{
				host: process.env.NEXT_PUBLIC_TYPESENSE_HOST ?? "localhost",
				port: parseInt(process.env.NEXT_PUBLIC_TYPESENSE_PORT ?? "8108"),
				protocol: (process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL ?? "http") as "https" | "http",
			},
		],
		numRetries: 3,
		connectionTimeoutSeconds: 10,
		cacheSearchResultsForSeconds: 60 * 60,
	},
	additionalSearchParameters: {
		query_by: "title",
		perPage: 36,
		exclude_fields: "embedding",
		use_cache: true,
	},
});

const useSearch = () => {
	const router = useRouter();

	// @ts-ignore
	const [searchState, setSearchState] = useState<SearchState>(qs.parse(router.query));

	const setStateId = React.useRef<NodeJS.Timeout>();

	const onSearchStateChange = (nextSearchState: SearchState) => {
		// @ts-ignore
		clearTimeout(setStateId.current);

		setStateId.current = setTimeout(() => {
			router.push(searchStateToUrl(nextSearchState), undefined, {
				shallow: true,
			});
		}, config.createUrlDebounceTimeMs);

		setSearchState(nextSearchState);

		// @ts-ignore
		window?.gtag?.(
			"set",
			"page",
			(window.location.pathname + window.location.search).toLowerCase(),
		);
		// @ts-ignore
		window?.gtag?.("send", "pageView", nextSearchState.query);
	};

	const searchStateToUrl = (state: SearchState) =>
		state ? `${router.pathname}${createURL(state)}` : "";

	const createURL = (state: SearchState) => {
		return `?${qs.stringify(state)}`;
	};

	return { searchClient, createURL, searchState, onSearchStateChange };
};

export default useSearch;
