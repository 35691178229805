import { extendTheme, Input, NumberInput } from "@chakra-ui/react";

const colors = {
	black: {
		900: "#000000",
		800: "#000000",
		700: "#000000",
		600: "#000000",
		500: "#000000",
		400: "#000000",
		300: "#000000",
	},
};

const theme = extendTheme({
	...colors,
	initialColorMode: "light",
	useSystemColorMode: false,
	shadows: { outline: "0 0 0 3px var(--chakra-colors-teal-500)" },
	fonts: {
		heading: "'Inter', sans-serif;",
		body: "'Inter', sans-serif;",
	},
	styles: {
		global: (props: any) => ({
			body: {
				color: "gray.700",
			},
			a: {
				transition: "color 0.15s",
				transitionTimingFunction: "ease-out",
				fontWeight: "500",
			},
		}),
	},
	components: {
		Button: {
			baseStyle: {
				_focus: {
					boxShadow: "0 0 0 3px rgb(49 151 149 / 60%)",
				},
			},
		},
	},
	textStyles: {
		heading: {
			fontFamily: "heading",
			textAlign: "center",
			fontWeight: "bold",
			letterSpacing: "-0.015em",
			lineHeight: "1.24",
			fontSize: { base: "1.75rem", md: "1.75rem" },
		},
		"heading-2": {
			fontFamily: "heading",
			textAlign: "center",
			fontWeight: "bold",
			letterSpacing: "-0.015em",
			lineHeight: "1.24",
			fontSize: { base: "1.75rem", md: "1.75rem" },
		},
		caps: {
			textTransform: "uppercase",
			fontSize: "sm",
			letterSpacing: "widest",
			fontWeight: "bold",
		},
	},
	mdx: {
		h1: {
			mt: "2rem",
			mb: ".25rem",
			lineHeight: 1.2,
			fontWeight: "bold",
			fontSize: "1.875rem",
			letterSpacing: "-.025em",
		},
		h2: {
			mt: "4rem",
			mb: "0.5rem",
			lineHeight: 1.3,
			fontWeight: "semibold",
			fontSize: "1.5rem",
			letterSpacing: "-.025em",
			"& + h3": {
				mt: "1.5rem",
			},
		},
		h3: {
			mt: "3rem",
			lineHeight: 1.25,
			fontWeight: "semibold",
			fontSize: "1.25rem",
			letterSpacing: "-.025em",
		},
		h4: {
			mt: "3rem",
			lineHeight: 1.375,
			fontWeight: "semibold",
			fontSize: "1.125rem",
		},
		a: {
			color: "teal.500",
			fontWeight: "semibold",
			transition: "color 0.15s",
			transitionTimingFunction: "ease-out",
			_hover: {
				color: "teal.600",
			},
		},
		p: {
			mt: "1.25rem",
			lineHeight: 1.7,
			"blockquote &": {
				mt: 0,
			},
		},
		hr: {
			my: "4rem",
		},
		blockquote: {
			bg: "orange.100",
			borderWidth: "1px",
			borderColor: "orange.200",
			rounded: "lg",
			px: "1.25rem",
			py: "1rem",
			my: "1.5rem",
		},
		ul: {
			mt: "1.5rem",
			ml: "1.25rem",
			"blockquote &": { mt: 0 },
			"& > * + *": {
				mt: "0.25rem",
			},
		},
		code: {
			rounded: "sm",
			px: "1",
			fontSize: "0.875em",
			py: "2px",
			whiteSpace: "nowrap",
			lineHeight: "normal",
		},
	},
});

Input.defaultProps = { ...Input.defaultProps, focusBorderColor: "teal.500" };
NumberInput.defaultProps = { ...NumberInput.defaultProps, focusBorderColor: "teal.500" };

export default theme;
