/* eslint-disable react/react-in-jsx-scope */
import type { AppProps } from "next/app";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import { ChakraProvider, Stack } from "@chakra-ui/react";

import "../assets/global.css";

import dynamic from "next/dynamic";
import Script from "next/script";
import useSearch from "hooks/useSearch";
import { InstantSearch } from "react-instantsearch-dom";
import React from "react";
import theme from "../lib/theme";
import Navbar from "../components/Navbar";

const Feedback = dynamic(() => import("../components/Feedback"), {
	ssr: false,
});
const ScrollToTop = dynamic(() => import("../components/utils/ScrollToTop"), {
	ssr: false,
});

function MyApp({ Component, pageProps }: AppProps) {
	const isIFrame = pageProps.iframe as boolean;
	const showNavbar = !pageProps.home as boolean;

	const { createURL, onSearchStateChange, searchClient, searchState } = useSearch();

	return (
		<>
			<DefaultSeo
				title="Buck Cheap - South African Price Tracker"
				description="We track the price of items so you can make better informed decisions on what to buy."
				canonical="https://buck.cheap"
				openGraph={{
					type: "website",
					locale: "en_ZA",
					url: "https://buck.cheap",
					description:
						"We track the price of items so you can make better informed decisions on what to buy.",
					site_name: "Buck Cheap - South African Price Tracker",
					title: "Buck Cheap - South African Price Tracker",
				}}
			/>

			<Head>
				<meta content="IE=edge" httpEquiv="X-UA-Compatible" />
				<meta content="width=device-width, initial-scale=1" name="viewport" />
				<link rel="icon" href="/favicon.ico" type="image/x-icon" />
				<link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />
				<meta name="theme-color" content="#319795" />
			</Head>

			<Script
				id="google-analytics"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', 'G-LMC5LFZC2N');`,
				}}
			/>

			<ChakraProvider theme={theme}>
				{!isIFrame && (
					<Stack
						zIndex={30}
						direction="row"
						style={{ position: "fixed", bottom: "15px", right: "15px" }}>
						<ScrollToTop />
						<Feedback />
					</Stack>
				)}

				<InstantSearch
					indexName="Buck_Cheap"
					onSearchStateChange={onSearchStateChange}
					createURL={createURL}
					searchClient={searchClient}
					searchState={searchState}>
					{!isIFrame && showNavbar && <Navbar />}
					<Component {...pageProps} />
				</InstantSearch>
			</ChakraProvider>
		</>
	);
}

export default MyApp;
