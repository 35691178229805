const secondsPerMinute = 60;
const minutesPerHour = 60;
const hoursPerDay = 24;
const days = 7;

const secondsIn30Days = secondsPerMinute * minutesPerHour * hoursPerDay * days;

export default {
	/**
	 * Default debounce time.
	 */
	defaultDebounceTimeMs: 300,
	/**
	 * Delay before searching should update
	 * the hits.
	 */
	searchDebounceTimeMs: 300,
	/**
	 * Delay before updating the URL when a user
	 * refines a search.
	 */
	createUrlDebounceTimeMs: 500,
	/**
	 * Delay before sending analytics to Google
	 * about the search a user has performed.
	 */
	sendAnalyticsDebounceTimeMs: 2000,

	/**
	 * Default time to cache ISR pages for.
	 */
	defaultIsrTimeSeconds: secondsIn30Days,
};
